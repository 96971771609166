export const PROVABLY_FAIR_ROUTES = {
  INSTRUCTIONS: {
    name: 'provably-fair-instructions',
    path: '/instructions',
  },
  SETTINGS: {
    name: 'provably-fair-settings',
    path: '/settings',
  },
  CHECK: {
    name: 'provably-fair-check',
    path: '/check',
  },
  CASE_HISTORY: {
    getDynamicPath: (caseName: string) => `/case-history/${caseName}`,
    name: 'provably-fair-case-history',
    path: '/case-history/:caseName',
  },
};
