import { EHeaderMenuItems, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'dota',
    defaultCurrency: 'RUB',
    auth: {
      isCheckboxesSelectedByDefault: true,
      variants: ['steam', 'google', 'vk'],
    },
    cases: {
      showButtons: true,
    },
    contacts: {
      adMail: 'media_td@tastyteam.cc',
      supportMail: 'support@tastyteam.cc',
      partnerMail: 'partner@tastyteam.cc',
      hideVk: false,
    },
    faq: {
      type3: {
        categoryId: 5,
        questionId: 19,
      },
    },
    footer: {
      copyright: 'TastyDROP',
      paymentMethods: ['visa', 'mastercard', 'qiwi', 'yoomoney', 'webmoney', 'hotskins'],
      hideSocials: false,
    },
    legalData: {
      beneficiary: 'Big Corgi Development OÜ',
      registrationNumber: 16252850,
      address: 'Harju maakond, Tallinn, Kesklinna linnaosa, Masina tn 22, 10113',
    },
    isProvablyFairDisabled: true,
    isTechiesDisabled: false,
    hidePaymentBackBlock: false,
    hideSecretShop: false,
    hideFreeCasesBanner: false,
    hideHeaderMenuItems: [EHeaderMenuItems.LEADERBOARD, EHeaderMenuItems.PF],
    hideProjectSwitcher: false,
    supportKey: 'sePmTTSmEQ',
    seo: {
      favicon: '/img/favicon/dota.ico',
      title: 'Tastydrop - Кейсы Dota 2',
      description:
        'TastyDrop — это самый крупный сайт с кейсами по Дота 2. Покупай кейсы, получай арканы и имморталки! Вывод на Steam в течение 1 минуты.',
    },
    statistics: {
      hideElements: [],
    },
    techWork: {
      logoClass: 'logo--dota',
    },
    redirects: {
      newCases: '/new-cases',
    },
  };
