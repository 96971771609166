<template>
  <SvgoTechWorkLogo :class="['logo', logoClass]" />
</template>

<script lang="ts" setup>
const {
  techWork: { logoClass },
} = useProjectSettings();
</script>

<style lang="scss" scoped src="./Logo.scss" />
