<template>
  <Teleport to="#teleports">
    <Transition name="fade" mode="out-in" @after-enter="handleClickOutside">
      <div v-if="isOpen && !isAuth">
        <section ref="popupRef" class="popup-controller">
          <div class="popup-controller__close" @click="authPopupStore.close">
            <SvgoCloseIcon class="popup-controller__close-icon" />
          </div>
          <div class="popup-controller__content">
            <GeneralAuthInstruction :buttons-amount="variants.length" />
            <GeneralAuthCheckboxes
              class="popup-controller__content-checkboxes"
              :is-selected-by-default="isCheckboxesSelectedByDefault"
              @is-all-checkboxes-checked="handleChecked"
              @close-popup="isOpen = !isOpen"
            />
            <GeneralAuthButtonsGroup
              class="popup-controller__content-buttons"
              :variants="variants"
              :is-disabled="!isAllCheckboxesChecked"
              @init-auth="handleAuth"
            />
          </div>
        </section>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import type { TOAuthKey } from '~/features/general-auth/components/ButtonsGroup/ButtonsGroup.types';
import { AuthEvents } from '~/repository/amplitude/events/auth';

const {
  auth: { isCheckboxesSelectedByDefault, variants },
} = useProjectSettings();

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const authPopupStore = useAuthPopupStore();
const { isOpen } = storeToRefs(authPopupStore);

const signupAmplitude = useSignupAmplitude();

const popupRef = ref<HTMLElement>();
const isAllCheckboxesChecked = ref(false);

const removeClickOutside = ref<() => void>();

const handleChecked = (value: boolean) => {
  isAllCheckboxesChecked.value = value;
};

const handleClickOutside = () => {
  if (!popupRef.value) return;
  const { remove } = useClickOutside(popupRef.value, authPopupStore.close);
  removeClickOutside.value = remove;
};

const handleAuth = async (key: TOAuthKey) => {
  signupAmplitude.saveType(key);
  signupAmplitude.saveCreationData();

  AuthEvents.buttonClicked({
    SignUpType: key,
    Url: location.href,
  });

  await authStore.oauth(key);
};

watch(isOpen, (value) => {
  if (value || !removeClickOutside.value) return;
  removeClickOutside.value();
});
</script>

<style lang="scss" scoped src="./PopupController.scss" />
