export default defineNuxtRouteMiddleware((to) => {
  const { project } = useProjectSettings();

  const baseRoute = useRouteBaseName();
  const localeRoute = useLocaleRoute();

  const toName = baseRoute(to);

  const isToPrivacyPolicy = toName === ROUTING.FOOTER_PAGES.PRIVACY_POLICY.name;
  const isToEventCondition = toName === ROUTING.FOOTER_PAGES.EVENT_CONDITION.name;

  if (project === 'dota-global' && isToEventCondition) {
    return navigateTo(localeRoute(ROUTING.FOOTER_PAGES.PRIVACY_POLICY));
  }

  if (project !== 'dota-global' && isToPrivacyPolicy) {
    return navigateTo(localeRoute(ROUTING.FOOTER_PAGES.EVENT_CONDITION));
  }
});
