import CasesHttp from '~/repository/modules/cases/CasesHttp';
import type { CaseResponseDto } from '~/repository/modules/cases/cases.dto';
import type { ICaseData, IOpenItems, TChallangeData } from '~/repository/modules/cases/cases.types';
import type { TPossibleNull } from '~/types/Shared.types';

export default class CasesService {
  private readonly caseHttp: CasesHttp;

  constructor() {
    this.caseHttp = new CasesHttp();
  }

  public getSections() {
    return this.caseHttp.getSections();
  }

  public async getEventCases(): Promise<ICaseData[]> {
    const data = await this.caseHttp.getEventCases();
    return data || [];
  }

  public async getCase(caseName: string): Promise<TPossibleNull<CaseResponseDto>> {
    const data = await this.caseHttp.getCase(caseName);
    return data || null;
  }

  public async openCase(caseName: string): Promise<TPossibleNull<IOpenItems>> {
    const data = await this.caseHttp.openCase(caseName);
    return data || null;
  }

  public getDrops(caseName: string, gameId: string) {
    return this.caseHttp.getDrops(caseName, gameId);
  }

  public getMaxCasePriceForUser() {
    return this.caseHttp.getMaxCasePriceForUser();
  }

  public async getChallangeProgress(caseName?: string): Promise<TPossibleNull<TChallangeData>> {
    const data = await this.caseHttp.getChallangeProgress(caseName);
    return data || null;
  }
}
